import { Box, Button, Image, Link, Stack, Text } from '@chakra-ui/react';
import './App.css';
import { useState } from 'react';

function App() {
  const [playing, isPlaying] = useState(false);
  const playAudio = () => {
    const audio = document.getElementsByClassName('player')[0];
    if (!playing) {
      audio.play();
      isPlaying(true);
    } else {
      audio.pause();
      isPlaying(false);
    }
  };
  return (
    <Stack
      w={'full'}
      minH={'100vh'}
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}
      bg={'radial-gradient(circle, rgba(4,51,5,1) 0%, rgba(6,6,6,1) 80%)'}
    >
      <Text px={'2rem'} color={'#fff'} textAlign={'center'}>
        Dacă ai ajuns până aici, intențiile tale au fost bune, dar adresa web a
        alianței este{' '}
        <Link
          color={'teal'}
          textDecor={'underline'}
          fontWeight={'bold'}
          textTransform={'uppercase'}
          href="http://www.aliantadreaptaunita.ro/"
          target="_blank"
          aria-label="Facebook"
          rel="noreferrer"
        >
          aliantadreaptaunita.ro
        </Link>
      </Text>
      <Image
        w={'full'}
        objectFit={'cover'}
        objectPosition={'top'}
        maxH={'calc(100vh - 100px)'}
        src={'/images/opris.webp'}
        alt={'Cavaleria aliantei dreptei unite'}
      />
      <Box>
        <Button onClick={playAudio}>Apasă-mă</Button>
        <audio className="player">
          <source src="/audio/piesa.mp3"></source>
        </audio>
      </Box>
    </Stack>
  );
}

export default App;
